import { Box, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import { parseToHtml } from '~/utils/html';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

interface IProps {
  title: string;
  className?: string;
  titleClassName?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
}

const VerticalTabItemTitle: React.FunctionComponent<IProps> = ({
  onClick,
  onMouseEnter,
  className,
  titleClassName,
  title,
}) => {
  const classes = useStyles();

  const getTextSection = () => {
    const boxProps = {
      ...(onClick
        ? {
            onClick: (event: any) => {
              event.preventDefault();
              if (onClick) {
                onClick();
              }
            },
          }
        : {}),
      ...(onMouseEnter
        ? {
            onMouseEnter: () => {
              if (onMouseEnter) {
                onMouseEnter();
              }
            },
          }
        : {}),
    };

    return (
      <Box {...boxProps}>
        <h3
          className={cn(
            classes.title,
            'mobile-t3-semibold md:web-t3-semibold',
            titleClassName
          )}
        >
          {parseToHtml(title)}
        </h3>
      </Box>
    );
  };

  return <div className={cn(classes.root, className)}>{getTextSection()}</div>;
};

export default VerticalTabItemTitle;
