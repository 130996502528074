import { Container } from '@finn/atoms';
import { Box, Hidden, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useState } from 'react';

import {
  VerticalTabCosmicMetadata,
  VerticalTabItemData,
} from '~/types/UIModules';
import { parseToHtml } from '~/utils/html';

import VerticalTabItem from './VerticalTabItem';
import VerticalTabItemContent from './VerticalTabItemContent';
import VerticalTabItemTitle from './VerticalTabItemTitle';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(7),
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: '5fr 7fr',
      gridGap: theme.spacing(3),
    },
  },
  title: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5),
    },
  },
  textTitle: {
    cursor: 'default',
  },
  selectedText: {
    textDecoration: 'underline',
  },
  fitContent: {
    width: 'fit-content',
  },
}));

type Props = {
  data: VerticalTabCosmicMetadata;
};

const VerticalTab: React.FunctionComponent<Props> = ({ data }) => {
  const classes = useStyles();
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const getItemAt = (index: number) => {
    return data?.items?.length > index
      ? data.items[index].metadata
      : ({} as VerticalTabItemData);
  };

  const getSelectedItem = () => getItemAt(selectedItemIndex);

  const handleObjectAction = useCallback(
    (index: number) => {
      if (index !== selectedItemIndex) {
        setSelectedItemIndex(index);
      }
    },
    [selectedItemIndex]
  );

  const getTextSection = useCallback(
    (index: number) => {
      const item = getItemAt(index);
      const props = { title: item?.title, titleClassName: classes.textTitle };
      if (index === selectedItemIndex) {
        return (
          <VerticalTabItemTitle
            className={cn(classes.selectedText)}
            {...props}
          />
        );
      } else {
        return (
          <VerticalTabItemTitle
            {...props}
            onClick={() => handleObjectAction(index)}
            onMouseEnter={() => handleObjectAction(index)}
          />
        );
      }
    },
    [classes, getItemAt, selectedItemIndex, handleObjectAction]
  );

  return (
    <>
      <Container>
        {data.title && (
          <Box className={classes.title}>
            <h2 className="mobile-t2-semibold md:web-t2-semibold">
              {parseToHtml(data.title)}
            </h2>
          </Box>
        )}
        <Hidden mdUp implementation={'css'}>
          <Box className={classes.wrapper}>
            {data?.items?.map(({ metadata }) => (
              <VerticalTabItem key={metadata.title} item={metadata} />
            ))}
          </Box>
        </Hidden>
        <Hidden smDown implementation={'css'}>
          <Box className={classes.wrapper}>
            <Box>
              {data?.items?.map(({ metadata }, index: number) => (
                <Box className={classes.fitContent} key={metadata.title}>
                  {getTextSection(index)}
                </Box>
              ))}
            </Box>

            <VerticalTabItemContent item={getSelectedItem()} />
          </Box>
        </Hidden>
      </Container>
    </>
  );
};

export default VerticalTab;
