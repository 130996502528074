import React from 'react';

import { VerticalTabItemData } from '~/types/UIModules';
import { parseToHtml } from '~/utils/html';

interface IProps {
  item: VerticalTabItemData;
}

const HowItWorksItemImage: React.FunctionComponent<IProps> = ({ item }) => {
  return (
    <div>
      <h5 className="global-t5-semibold mb-4">{parseToHtml(item?.subtitle)}</h5>
      <div className="body-16-light">{parseToHtml(item?.description)}</div>
    </div>
  );
};

export default HowItWorksItemImage;
